const WX = function(){
    const appid = "wxd98bc08552dbc66d";
    const secret = "b1a3615fd7f1d50a12dba8c1839d526c";
    return {
        getUrl() {
            const WxUrl = "https://open.weixin.qq.com/connect/oauth2/authorize";
            const redirect_uri = encodeURIComponent("https://sign.isagzth.com/#/getcode"); //"https://api2.isagzth.com/activity/get-user-info/";//
            const response_type = "code";
            const scope = "snsapi_userinfo";
            const state =  encodeURIComponent(JSON.stringify({
                p:"/getcode",
            }));
            const sendUrl = `${WxUrl}?appid=${appid}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}#wechat_redirect`;
            return sendUrl;
        }
    }
}()

export default WX;