<template>
    <div id="page" v-if="activity">
        <div class="nav center">
            <van-row>
                <van-col offset="4" span="16">
                    <text style="color:#fff"> {{common.changeLanguage(activity.en_name,activity.cn_name,lang.language)}}</text>
                </van-col>
                <van-col span="4" >
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="main">
            <van-row>
                <van-col offset="1" span="22">
                    <img class="activity_image" :src="activity.image || Config.defaultMainImg" />
                </van-col>
            </van-row>
            
            <van-row>
                <van-col offset="1" span="22">
                    <div :style="`background-image:url(${common.getRes('ticket_bg.png')});background-size:100%;background-repeat:y-repeat;`">
                        <van-row>
                            <van-col offset="2" span="20">
                                <div class="h1 row bottom-line">
                                    {{common.changeLanguage(activity.en_name,activity.cn_name,lang.language)}}
                                </div>
                            </van-col>
                        </van-row>
                        <van-row>
                            <van-col offset="2" span="20">
                                <div class="row">
                                <van-row>
                                    <van-col span="24">
                                        <div class="h3">
                                            {{activity.start_date}} {{activity.start_time.substr(0,5)}} - {{activity.end_time.substr(0,5)}}
                                        </div>
                                    </van-col>
                                    <van-col span="24">
                                        <div class="h3">
                                            {{common.changeLanguage(activity.en_location,activity.cn_location,lang.language)}}
                                        </div>
                                    </van-col>
                                </van-row>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </van-col>
            </van-row>
            <div class="info">
                <van-row>
                    <van-col offset="2" span="20">
                        <div class="row bottom-line_solid">
                            <div class="info-title">
                            {{lang.e_ticket}}
                            </div>
                        </div>
                        <div class="row bottom-line_solid">
                            <van-row>
                                <van-col span="6">
                                    <span class="inputname">{{lang.participant}}</span>
                                </van-col>
                                <van-col offset="1" span="14">
                                    <input type="text" v-model="form.name" :placeholder="lang.please_fill_in_the_name" />
                                </van-col>
                            </van-row>
                        </div>
                        <div class="row bottom-line_solid">
                            <van-row>
                                <van-col span="6">
                                    <span class="inputname">{{lang.phone_no}}</span>
                                </van-col>
                                <van-col offset="1" span="14">
                                    <input  type="text" v-model="form.phone" :placeholder="lang.please_fill_in_the_phone_no" />
                                </van-col>
                            </van-row>
                        </div>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div id="footer">
            <van-row>
                <van-col offset="1" span="22">
                    <button class="btn" @click="submit">{{lang.joinIn}}</button>
                </van-col>
            </van-row>
        </div>
    </div>
</template>
<script>

import { reactive, toRefs ,onMounted } from 'vue';
import Config from "../library/Config";
import Common from "../library/Common";
import { useRouter } from 'vue-router';
import Validation from '../library/Validation';
import myRequest from '../library/MyRequest';
import { Toast } from 'vant';
import wx from '../library/wx';
import "../css/form.css";

export default {
  name: 'login',
  setup(){
    const router = useRouter();
    const common = Common;
    const id = router.currentRoute.value.query.id || null;
    let state = reactive({
        lang:{
            e_ticket:"",
            participant:"",
            phone_no:"",
            please_fill_in_the_name:"",
            please_fill_in_the_phone_no:"",
            joinIn:"",
            language:null,
        },
        form:{
            name:"",
            phone:'',
        },
        activity:null,
    });

    common.initLanguage(state.lang);
    state.lang.langBtn = common.getLanguageBtn();
    const toggleLanguage = function(){
        common.toggleLanguage();
        common.initLanguage(state.lang);
        state.lang.language = common.getCurrentLanguage();
        state.lang.langBtn = common.getLanguageBtn();
    }

    const getActivity = async()=>{
        var res = await myRequest.get(`activity/activity/${id}/`);
        if(res.status == 200){
            state.activity = res.data;
            var userInfo = common.getUserInfo(id);
            if(userInfo){
                common.setCurrentActivityId(id);
                goTicket(userInfo);
            }
        }
    }
    const goTicket = function(userInfo){
        //router.push({name: 'ticket'});  //浏览器调试开启
        //return ;
        var wxUserInfo = common.getWxUserInfo(userInfo);
        if(wxUserInfo){
            router.push({name: 'ticket'});
        }else{
          window.location.href=wx.getUrl();
        }
    }
    const submit = async()=>{
        let rules = [
            {label:'name',ruleValidate:[`required|message:${common.getLanguage('participant_must_be_required')}`,`length|min:2,max:12,message:${common.getLanguage('participant_can_only_be_characters')}`]},
            {label:'phone',ruleValidate:[`required|message:${common.getLanguage("phone_no_must_be_required")}`,`phone|message:${common.getLanguage("phoneNumberError")}`]}
        ];
        let form =  Validation.validate(state.form,rules);
        let error = Validation.getFirstError(form);
        if(error != null){
          Toast(error);
          return ;
        }

        var send = {
            activity_id:id,
            phone:state.form.phone,
            name:state.form.name,
        }
        var res = await myRequest.post("activity/free-ticket/",send);
        if(res.status == 200){
            var userInfo = res.data.code;
            userInfo.loginInfo = {
                phone:state.form.phone,
                name:state.form.name,
            }
            common.setUserInfo(userInfo,id);
            //查找此活动是否已授权，如未授权则进入授权页面，已经授权直接进入票卡页面
            goTicket(userInfo);
        }
    }

    onMounted(()=>{
        if(id){
            state.lang.language = common.getCurrentLanguage();
            getActivity();
        }
    });
    
    return {
        ...toRefs(state),
        common,
        Config,
        toggleLanguage,
        submit
    };
  }
}
</script>

<style scoped>
#page{
    width: 100%;
    height: 90%;
    background-color: #fff;
    text-align: left;
}

.main{
    height:calc(100% - 60px - 50px);
    overflow-y:auto;
    background-color: #2A3F54;
}
.activity_image{
    width: 100%;
    border-radius: 20px;
}

.title{
    color: #fff;
    font-size: 28px;
    padding:20px 0;
}

.info{
  background-color: #fff;
  margin-top:40px;
  border-radius: 30px 30px 0 0;
  padding:20px 0;
  height:60%;
  display:block;
}

.info-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom:30px;
}

.info-content{
  margin-bottom:30px;
}

::-webkit-input-placeholder {
    font-size: 32px;
}
input {
    border:0px;
    font-size: 32px;
}
.nav{
    background-color: #2A3F54;
}
#footer{
    background-color: #fff;
    position:relative;
    bottom: 0px;
    width:100%;
    height:120px;
    z-index:2;
    border:1px solid #fff;
    padding-top:20px;
    
}
.btn{
    background-color:#2A3F54;
    border:0px;
    border-radius: 10px;
    color:#fff;
    font-size: 32px;
    width: 100%;
    height:100px;
}

.inputname{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color:#000;
    font-size:32px;
    font-weight: 600;
}
</style>
